<template>
  <section>
    <b-row>
      <b-col>
        <b-card no-body>
          <div class="card-header">
            <!-- Title & SubTitle -->
            <div>
              <b-card-title></b-card-title>
              <b-card-sub-title></b-card-sub-title>
            </div>

            <!-- Card Actions -->
            <div class="heading-elements">
              <ul class="list-inline mb-0">
                <li></li>
                <li></li>
              </ul>
            </div>
          </div>

          <!-- Card Body  -->
          <b-card-body>
            <b-row>
              <b-col>
                <b-list-group>
                  <b-list-group-item
                    ><b>{{ $t("Id") }}:</b>
                    {{ this.invocation_id }}</b-list-group-item
                  >
                  <b-list-group-item
                    ><b>{{ $t("Name") }}:</b>
                    {{ this.invocation_name }}</b-list-group-item
                  >
                  <b-list-group-item
                    ><b>{{ $t("Created At") }}:</b>
                    {{ this.invocation_created_at }}</b-list-group-item
                  >

                  <b-list-group-item
                    ><b>{{ $t("Tipo Function Objetivo") }}:</b>
                    {{
                      this.invocation_tipo_funcion_objetivo
                    }}</b-list-group-item
                  >

                  <b-list-group-item
                    ><b>{{ $t("Tipo Ajuste") }}:</b>
                    {{ this.invocation_tipo_ajuste }}</b-list-group-item
                  >

                  <b-list-group-item
                    ><b>{{ $t("Status") }}:</b>
                    {{ this.invocation_status }}</b-list-group-item
                  >

                  <b-list-group-item
                    ><b>{{ $t("Generación Actual") }}:</b>
                    {{ this.invocation_current_generation }}</b-list-group-item
                  >

                  <b-list-group-item
                    ><b>{{ $t("Tiempo Restante") }}:</b>
                    {{
                      this.invocation_tiempo_restante_human
                    }}</b-list-group-item
                  >
                </b-list-group>
              </b-col>

              <b-col>
                <b-form-tags
                  v-model="tag_names"
                  :placeholder="$t('Add tags...')"
                  @input="tagInput"
                ></b-form-tags>
              </b-col>

              <b-col>
                <b-form-checkbox
                  v-model="liveReload"
                  name="check-button"
                  @change="liveReloadChange"
                  button
                  button-variant="outline-info"
                >
                  <feather-icon
                    icon="RefreshCwIcon"
                    :badge="updatesCount"
                    badge-classes="badge-info badge-glow"
                    size="21"
                  />
                </b-form-checkbox>
              </b-col>
            </b-row>
          </b-card-body>

          <template #footer>
            <b-progress height="5px" variant="info" striped :animated="true" :value="generation" :max="max_generation"></b-progress>
          </template>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-button
          variant="flat-secondary"
          class="btn-icon float-right"
          v-b-toggle.sidebar-1
        >
          <feather-icon icon="HelpCircleIcon" size="20" />
        </b-button>
      </b-col>

      <b-sidebar
        id="sidebar-1"
        title="Resultado de Optimizaciones"
        shadow
        bg-variant="dark"
        text-variant="light"
        width="450px"
        right
        backdrop
      >
        <div class="px-2 py-2">
          <p>
            En esta vista encontrar&aacute;s todos los resultados asociados a la
            optimizaci&oacute;n realizada. A continuaci&oacute;n puedes
            encontrar detalles de cada KPI asociado a esta optimizaci&oacute;n.
          </p>

          <hr />
          <p>
            <strong>Numero de Generaciones</strong>: Los algortimos utilizado
            para resolver este problema, se denominan algoritmos
            gen&eacute;ticos y utilizan las bases bioligicas reproductivas del
            ser humano. Como tal, las generaciones corresponden a la cantidad de
            padres, hijos, nietos, bisnietos, ... tatara tatara nietos que
            genere el algoritmo para encontrar la soluci&oacute;n &oacute;ptima.
            &iquest;C&oacute;mo impacta en los resultados? Si escogemos 100
            generaciones, los algoritmos se demoran menos en encontrar una
            soluci&oacute;n, pero es menos probable que sea el &oacute;ptimo del
            problema. Por otro lado, si escogemos 500 generaciones, los
            algoritmos se demoran m&aacute;s tiempo en encontrar una
            soluci&oacute;n, pero es mucho m&aacute;s probable que esta
            soluci&oacute;n sea la &oacute;ptima.
          </p>

          <hr />
          <p>
            <strong>N&deg; Variables Modificadas:</strong> KPI que refleja la
            cantidad de variables que el usuario defini&oacute; (al momento de
            hacer la optiizaci&oacute;n) que el algoritmo puede modificar para
            encontrar una configuarci&oacute;n &oacute;ptima de consumo de
            vapor.
          </p>

          <hr />
          <p>
            <strong>Costo Vapor Hora (USD/t papel):</strong> Valor definido al
            hacer la optimizaci&oacute;n y que se utiliza para valorizar el
            consumo y el ahorro de vapor generado.
          </p>

          <hr />
          <p>
            <strong>Consumo Optimizado Vapor CE (t/h):</strong> Este Consumo de
            vapor CE es calculado utilizando los valores sugeridos por el
            algoritmo, para cada una de las variables clasificadas como
            &ldquo;Modificables&rdquo; y utilizando el modelamiento de consumo
            hist&oacute;rico.
          </p>

          <hr />
          <p>
            <strong
              >Consumo Optimizado Vapor Media Presi&oacute;n (t/h):</strong
            >
            Este Consumo de Vapor Media Presi&oacute;n es calculado utilizando
            los valores sugeridos por el algoritmo, para cada una de las
            variables clasificadas como &ldquo;Modificables&rdquo; y utilizando
            el modelamiento de consumo hist&oacute;rico.
          </p>

          <hr />
          <p>
            <strong>Consumo Optimizado Vapor Baja Presi&oacute;n (t/h):</strong>
            Este Consumo de Vapor Baja Presi&oacute;n es calculado utilizando
            los valores sugeridos por el algoritmo, para cada una de las
            variables clasificadas como &ldquo;Modificables&rdquo; y utilizando
            el modelamiento de consumo hist&oacute;rico.
          </p>

          <hr />
          <p>
            <strong>% Disminuci&oacute;n Consumo Vapor CE (t/h):</strong> El %
            de Disminuci&oacute;n es calculado usando como base el escenario
            inicial entregado, proyectando su respectivo consumo, y
            compar&aacute;ndolo con el consumo de vapor CE proyectado con los
            valores optimizados. Consumo Vapor CE Escenario Optimo / Consumo
            Vapor CE Escenario Inicial
          </p>

          <hr />
          <p>
            <strong
              >% Disminuci&oacute;n Consumo Vapor Media Presi&oacute;n
              (t/h):</strong
            >
            El % de Disminuci&oacute;n es calculado usando como base el
            escenario inicial entregado, proyectando su respectivo consumo, y
            compar&aacute;ndolo con el consumo de vapor Media Presi&oacute;n
            proyectado con los valores optimizados. Consumo Vapor Media
            Presi&oacute;n Escenario Optimo / Consumo Vapor Media Presi&oacute;n
            Escenario Inicial
          </p>

          <hr />
          <p>
            <strong
              >% Disminuci&oacute;n Consumo Vapor Baja Presi&oacute;n
              (t/h):</strong
            >
            El % de Disminuci&oacute;n es calculado usando como base el
            escenario inicial entregado, proyectando su respectivo consumo, y
            compar&aacute;ndolo con el consumo de vapor Baja Presi&oacute;n
            proyectado con los valores optimizados. Consumo Vapor Baja
            Presi&oacute;n Escenario Optimo / Consumo Vapor Baja Presi&oacute;n
            Escenario Inicial
          </p>

          <hr />
          <p>
            <strong>Ahorro Consumo Vapor CE (USD t/h):</strong> Valor calculado
            utilizando la diferencia de los consumos de vapor CE proyectados del
            escenario inicial y del escenario optimizados. Se utiliza el valor
            Costo Vapor Hora (USD/t papel) ingresado en la optimizaci&oacute;n
            para la valorizaci&oacute;n.
          </p>

          <hr />
          <p>
            <strong>Ahorro Vapor Media Presi&oacute;n (USD t/h):</strong> Valor
            calculado utilizando la diferencia de los consumos de vapor Media
            Presi&oacute;n proyectados del escenario inicial y del escenario
            optimizados. Se utiliza el valor Costo Vapor Hora (USD/t papel)
            ingresado en la optimizaci&oacute;n para la valorizaci&oacute;n.
          </p>

          <hr />
          <p>
            <strong>Ahorro Vapor Baja Presi&oacute;n (USD t/h):</strong> Valor
            calculado utilizando la diferencia de los consumos de vapor Baja
            Presi&oacute;n proyectados del escenario inicial y del escenario
            optimizados. Se utiliza el valor Costo Vapor Hora (USD/t papel)
            ingresado en la optimizaci&oacute;n para la valorizaci&oacute;n.
          </p>
        </div>
      </b-sidebar>
    </b-row>

    <b-tabs>
      <b-tab title="Predicciones Por Pilas (4 Horas)">
        <b-row>
          <b-col>
            <b-card no-body>
              <div class="card-header">
                <!-- Title & SubTitle -->
                <div>
                  <b-card-title>Resultado Predicción CV12</b-card-title>
                  <b-card-sub-title></b-card-sub-title>
                </div>

                <!-- Card Actions -->
                <div class="heading-elements">
                  <ul class="list-inline mb-0">
                    <li></li>
                    <li></li>
                  </ul>
                </div>
              </div>

              <!-- Card Body  -->
              <b-card-body>
                <stats :dataUrl="detalleOptimizacionStats"></stats>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-card no-body>
              <div class="card-header">
                <!-- Title & SubTitle -->
                <div>
                  <b-card-title>Resultado Predicción CV14</b-card-title>
                  <b-card-sub-title></b-card-sub-title>
                </div>

                <!-- Card Actions -->
                <div class="heading-elements">
                  <ul class="list-inline mb-0">
                    <li></li>
                    <li></li>
                  </ul>
                </div>
              </div>

              <!-- Card Body  -->
              <b-card-body>
                <stats :dataUrl="detalleOptimizacionStats2"></stats>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
      </b-tab>

      <b-tab title="Mezcla de Pilas (4 Horas)">
        <b-row>
          <b-col>
            <b-card no-body>
              <div class="card-header">
                <!-- Title & SubTitle -->
                <div>
                  <b-card-title>Resultado Mezcla (CV12 + CV14)</b-card-title>
                  <b-card-sub-title></b-card-sub-title>
                </div>

                <!-- Card Actions -->
                <div class="heading-elements">
                  <ul class="list-inline mb-0">
                    <li></li>
                    <li></li>
                  </ul>
                </div>
              </div>

              <!-- Card Body  -->
              <b-card-body>
                <stats :dataUrl="detalleOptimizacionStats3"></stats>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <bruna-element-loader
              :dataUrl="detalleOptimizacionMezclaChart"
            ></bruna-element-loader>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <bruna-element-loader
              :dataUrl="detalleOptimizacionMezclaChart2"
            ></bruna-element-loader>
          </b-col>

          <b-col>
            <bruna-element-loader
              :dataUrl="detalleOptimizacionMezclaChart3"
            ></bruna-element-loader>
          </b-col>
        </b-row>
      </b-tab>

      <b-tab title="Predicciones Por Pilas (8 Horas)">
        <b-row>
          <b-col>
            <b-card no-body>
              <div class="card-header">
                <!-- Title & SubTitle -->
                <div>
                  <b-card-title>Resultado Predicción CV12</b-card-title>
                  <b-card-sub-title></b-card-sub-title>
                </div>

                <!-- Card Actions -->
                <div class="heading-elements">
                  <ul class="list-inline mb-0">
                    <li></li>
                    <li></li>
                  </ul>
                </div>
              </div>

              <!-- Card Body  -->
              <b-card-body>
                <stats :dataUrl="detalleOptimizacion8HorasStats"></stats>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-card no-body>
              <div class="card-header">
                <!-- Title & SubTitle -->
                <div>
                  <b-card-title>Resultado Predicción CV14</b-card-title>
                  <b-card-sub-title></b-card-sub-title>
                </div>

                <!-- Card Actions -->
                <div class="heading-elements">
                  <ul class="list-inline mb-0">
                    <li></li>
                    <li></li>
                  </ul>
                </div>
              </div>

              <!-- Card Body  -->
              <b-card-body>
                <stats :dataUrl="detalleOptimizacion8HorasStats2"></stats>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
      </b-tab>

      <b-tab title="Mezcla de Pilas (8 Horas)">
        <b-row>
          <b-col>
            <b-card no-body>
              <div class="card-header">
                <!-- Title & SubTitle -->
                <div>
                  <b-card-title>Resultado Mezcla (CV12 + CV14)</b-card-title>
                  <b-card-sub-title></b-card-sub-title>
                </div>

                <!-- Card Actions -->
                <div class="heading-elements">
                  <ul class="list-inline mb-0">
                    <li></li>
                    <li></li>
                  </ul>
                </div>
              </div>

              <!-- Card Body  -->
              <b-card-body>
                <stats :dataUrl="detalleOptimizacion8HorasStats3"></stats>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <bruna-element-loader
              ref="detalle-optimizacion-mezcla-8horas-chart"
              :dataUrl="detalleOptimizacionMezcla8HorasChart"
            ></bruna-element-loader>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <bruna-element-loader
              :dataUrl="detalleOptimizacionMezcla8HorasChart2"
            ></bruna-element-loader>
          </b-col>

          <b-col>
            <bruna-element-loader
              :dataUrl="detalleOptimizacionMezcla8HorasChart3"
            ></bruna-element-loader>
          </b-col>
        </b-row>
      </b-tab>
    </b-tabs>

    <b-row>
      <b-col>
        <ag-grid-table
          ref="optimization-comments-table"
          :debug="this.$route.query.debug == '1'"
          :configUrl="commentsConfig"
          :dataUrl="commentsData"
        >
          <template v-slot:footer>
            <div style="margin-top: 2rem">
              <b-input-group>
                <b-form-input
                  v-model="nuevo_comentario"
                  :placeholder="$t('Ingresar nuevo comentario...')"
                ></b-form-input>
                <b-input-group-append>
                  <b-button
                    @click="nuevoComentario"
                    variant="outline-primary"
                    :disabled="nuevo_comentario.trim().length <= 0"
                    >{{ $t("Guardar Comentario") }}</b-button
                  >
                </b-input-group-append>
              </b-input-group>
            </div>
          </template>
        </ag-grid-table>
      </b-col>
    </b-row>
  </section>
</template> 
      
      <script>

import CardTooltip from '@/views/brunacomponents/wrappers/CardTooltipWrapper.vue';
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import BrunaElementLoader from '@/views/brunacomponents/BrunaElementLoader.vue'

import AgGridTable from '@/views/brunacomponents/ag-grid-table/AgGridTable.vue'

import Stat from '@/views/clients/base/components/Stat.vue'

import Stats from './Stats.vue';

import moment from 'moment';

import HeaderCellRenderer from '@/views/brunacomponents/ag-grid-table/cell-renderers/HeaderCellRenderer.vue';

import { AgGridVue } from "ag-grid-vue";

import axios from '@axios'

import
{
  BTabs,
  BTab,
  BOverlay,
  BSpinner,
  BCard,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BFormTags,
  BFormCheckboxGroup,
  BFormCheckbox,

  BListGroup,
  BListGroupItem,
  BProgress
} from "bootstrap-vue";

var qs = require('qs');
import useApiServices from '@/services/useApiServices.js';


export default {



  data()
  {
    let optimization_id = this.$route.query.optimization_id;

    let query = qs.stringify({
      optimization_id: optimization_id
    })

    return {
      showOverlay: false,

      liveReload: false,

      updatesCount: '',

      commentsConfig: useApiServices.invocations.comments.historico.config + "?" + query,
      commentsData: useApiServices.invocations.comments.historico.data + "?" + query,


      detalleOptimizacionMezclaChart: useApiServices.detalleOptimizacion.detalleOptimizacionMezclaChart + "?" + query,
      detalleOptimizacionMezclaChart2: useApiServices.detalleOptimizacion.detalleOptimizacionMezclaChart2 + "?" + query,
      detalleOptimizacionMezclaChart3: useApiServices.detalleOptimizacion.detalleOptimizacionMezclaChart3 + "?" + query,

      detalleOptimizacionMezcla8HorasChart: useApiServices.detalleOptimizacion.detalleOptimizacionMezcla8HorasChart + "?" + query,
      detalleOptimizacionMezcla8HorasChart2: useApiServices.detalleOptimizacion.detalleOptimizacionMezcla8HorasChart2 + "?" + query,
      detalleOptimizacionMezcla8HorasChart3: useApiServices.detalleOptimizacion.detalleOptimizacionMezcla8HorasChart3 + "?" + query,

      detalleOptimizacionStats: useApiServices.detalleOptimizacion.detalleOptimizacionStats + "?" + query,
      detalleOptimizacionStats2: useApiServices.detalleOptimizacion.detalleOptimizacionStats2 + "?" + query,
      detalleOptimizacion8HorasStats: useApiServices.detalleOptimizacion.detalleOptimizacion8HorasStats + "?" + query,
      detalleOptimizacion8HorasStats2: useApiServices.detalleOptimizacion.detalleOptimizacion8HorasStats2 + "?" + query,
      detalleOptimizacionStats3: useApiServices.detalleOptimizacion.detalleOptimizacionStats3 + "?" + query,
      detalleOptimizacion8HorasStats3: useApiServices.detalleOptimizacion.detalleOptimizacion8HorasStats3 + "?" + query,


      defaultColDef: {
        headerValueGetter: (header) => { return this.$t(header.column.userProvidedColDef.headerName) },
      },

      stats: [],
      stats2: [],
      stats3: [],

      stats_8horas: [],
      stats2_8horas: [],
      stats3_8horas: [],

      tag_names: [],

      nuevo_comentario: "",

      invocation_id: "",
      invocation_name: "",
      invocation_created_at: "",
      invocation_status: "",
      invocation_tipo_funcion_objetivo: "",
      invocation_tipo_ajuste: "",
      invocation_current_generation: '',
      invocation_tiempo_restante_human: '',
      generation: 0,
      max_generation: 100,
      
      

      refreshTime: new Date().getTime()


    };
  },

  beforeUnmount()
  {
    this.$pusher.unsubscribe('optimizations');
  },

  beforeRouteLeave(to, from, next)
  {

    this.$pusher.unsubscribe('optimizations');

    next()
  },


  mounted()
  {

    let optimization_id = this.$route.query.optimization_id;

    this.refreshData(optimization_id);

    let self = this;

    //Se escucha a la llegada de las notificaciones de pusher

    var channel = this.$pusher.subscribe('optimizations');

    channel.bind('OptimizationUpdateEvent', function (data)
    {

      let userData = JSON.parse(localStorage.getItem("userData"));

      if (userData && userData.client.id == data.client_id)
      {
        let invocation_id = data.invocation_id

        if (!self.liveReload)
        {
          if (self.updatesCount == '')
          {
            self.updatesCount = 1;
          } 
          else
          {
            self.updatesCount += 1;
          }

        }
        else
        {
          let toastId = data.content.id

          data.content.component = ToastificationContent;

          self.$toast.dismiss(toastId);
          self.$toast(data.content, { id: toastId });


        }

        if (optimization_id == invocation_id && self.liveReload)
        {
          self.refreshData(optimization_id);
        }


      }


    });




  },

  created()
  {

    let optimization_id = this.$route.query.optimization_id;

    useApiServices.getInvocationTags(optimization_id)
      .then((response) =>
      {
        this.tag_names = response.data.map((tag) => tag.name)
      })



  },

  components: {
    BTabs,
    BTab,
    BOverlay,
    BSpinner,
    BrunaElementLoader,
    AgGridTable,
    AgGridVue,


    BCard,
    BCardBody,
    BCardTitle,
    BCardSubTitle,

    Stat,
    BFormTags,

    BProgress,

    BListGroup,
    BListGroupItem,

    headerCellRenderer: HeaderCellRenderer,

    CardTooltip,
    Stats,

    BFormCheckboxGroup,
    BFormCheckbox


  },

  methods: {

    liveReloadChange(checked)
    {

      if (checked)
      {
        this.updatesCount = ''

        let optimization_id = this.$route.query.optimization_id;

        this.refreshData(optimization_id);

      }
    },

    refreshData(optimization_id)
    {


      this.getInvocationDetails(optimization_id)

      let refreshTime = new Date().getTime()

      let query = qs.stringify({
        optimization_id: optimization_id,
        ts: refreshTime
      })

      this.detalleOptimizacionMezclaChart = useApiServices.detalleOptimizacion.detalleOptimizacionMezclaChart + "?" + query,
        this.detalleOptimizacionMezclaChart2 = useApiServices.detalleOptimizacion.detalleOptimizacionMezclaChart2 + "?" + query,
        this.detalleOptimizacionMezclaChart3 = useApiServices.detalleOptimizacion.detalleOptimizacionMezclaChart3 + "?" + query,

        this.detalleOptimizacionMezcla8HorasChart = useApiServices.detalleOptimizacion.detalleOptimizacionMezcla8HorasChart + "?" + query,
        this.detalleOptimizacionMezcla8HorasChart2 = useApiServices.detalleOptimizacion.detalleOptimizacionMezcla8HorasChart2 + "?" + query,
        this.detalleOptimizacionMezcla8HorasChart3 = useApiServices.detalleOptimizacion.detalleOptimizacionMezcla8HorasChart3 + "?" + query,


        this.detalleOptimizacionStats = useApiServices.detalleOptimizacion.detalleOptimizacionStats + "?" + query,
        this.detalleOptimizacionStats2 = useApiServices.detalleOptimizacion.detalleOptimizacionStats2 + "?" + query,
        this.detalleOptimizacion8HorasStats = useApiServices.detalleOptimizacion.detalleOptimizacion8HorasStats + "?" + query,
        this.detalleOptimizacion8HorasStats2 = useApiServices.detalleOptimizacion.detalleOptimizacion8HorasStats2 + "?" + query,
        this.detalleOptimizacionStats3 = useApiServices.detalleOptimizacion.detalleOptimizacionStats3 + "?" + query,
        this.detalleOptimizacion8HorasStats3 = useApiServices.detalleOptimizacion.detalleOptimizacion8HorasStats3 + "?" + query


    },

    getInvocationDetails(invocation_id)
    {
      useApiServices.getInvocationDetails(invocation_id)
        .then((response) =>
        {

          let invocation_details = response.data

          this.invocation_id = invocation_details.id
          this.invocation_name = invocation_details.name
          this.invocation_created_at = localDateFormatter(invocation_details.created_at)
          this.invocation_status = invocation_details.status

          this.invocation_tipo_funcion_objetivo = invocation_details.tipo_funcion_objetivo
          this.invocation_tipo_ajuste = invocation_details.tipo_de_solucion

          this.generation = invocation_details.generation
          this.max_generation = invocation_details.max_generation

          this.invocation_current_generation = this.generation + " de " + this.max_generation

          let invocation_tiempo_restante = invocation_details.invocation_tiempo_restante
          var duration = moment.duration(invocation_tiempo_restante * 1000);

          this.invocation_tiempo_restante_human = duration.humanize()

        })

    },

    elementLoaded(event)
    {
      console.log("elementLoaded2", event)





    },

    nuevoComentario()
    {
      let invocation_id = this.$route.query.optimization_id;

      useApiServices.postInvocationCreateComment(invocation_id, { body: this.nuevo_comentario })
        .then((response) =>
        {
          this.$refs['optimization-comments-table'].refreshRows([], false)
          this.nuevo_comentario = ""
        })
        .catch((error) =>
        {

        });

    },

    tagInput(value)
    {
      let data = {
        tags: value
      }

      let optimization_id = this.$route.query.optimization_id;

      useApiServices.postInvocationAddTags(optimization_id, data)
        .then((response) =>
        {

        })

    }



  },

};
      </script>
  
  
  
    
    <style lang="scss" scoped>
@import "~ag-grid-community/dist/styles/ag-grid.css";
//@import "~ag-grid-community/dist/styles/ag-theme-alpine.css";
@import "~ag-grid-community/dist/styles/ag-theme-alpine.css";
</style>
      
    <style >
.btn-padding {
  margin-right: 48px;
}

.m-estadisticas {
  margin-left: 20px;
  margin-bottom: 1%;
  margin-top: 1%;
}
.icon-plan {
  padding-top: 5%;
}

.ag-row-pinned {
  background-color: #f0e68c !important;
}
</style>
      
    
    <style>
div.ag-watermark {
  display: none !important;
}
</style>
    